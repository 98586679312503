import { combineReducers } from "redux";
import sidebar from "./modules/Sidebar/reducer";
import auth from "./modules/User/reducer";
import alert from "./modules/Alert/reducer";
import category from "./modules/Category/reducer";
import feeds from "./modules/Feeds/reducer";

export default combineReducers({
  sidebar,
  auth,
  alert,
  category,
  feeds,
});
