import React, { useEffect } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import setAuthToken from "./utils/setAuthToken";
import store from "./store";
import { loadUser } from "./modules//User/actions";
import { LOGOUT } from "./modules//User/constants";
import "./scss/style.scss";
import Alert from "./widget/Alert";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./components/login/Login"));

const App = () => {
  useEffect(() => {
    // check for token in LS
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }
    store.dispatch(loadUser());

    // log user out from all tabs if they log out in one tab
    window.addEventListener("storage", () => {
      if (!localStorage.token) store.dispatch({ type: LOGOUT });
    });
  }, []);
  return (
    <BrowserRouter>
      <React.Suspense fallback={loading}>
        <Alert />
        <Switch>
          <Route
            exact
            path="/"
            name="Home"
            render={(props) => <Login {...props} />}
          />
          <Route name="Home" render={(props) => <TheLayout {...props} />} />
        </Switch>
      </React.Suspense>
    </BrowserRouter>
  );
};

export default App;
